// @flow
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import AbsoluteTime from 'components/AbsoluteTime'
import { useMediaQuery } from 'beautiful-react-hooks'
import transDomain from './translations/index.translations'
import { Store } from 'Store'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    lead: { [string]: mixed },
    campaign: { [string]: mixed },
}

const Voucher = React.forwardRef((props: Props, ref: React.Ref) => {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { lead, campaign, locale, currency } = state
    const currencyFormatter = Currency(currency, locale)
    const isMobile = useMediaQuery('(max-width: 767px)')

    const voucher = lead.incentives.voucher

    // const primaryColor = props.theme.primaryColor
    const secondaryColor = props.theme.secondaryColor
    const ternaryColor = props.theme.ternaryColor
    const website = lead.promoMaterial?.website || null
    const titleColor = props.theme.titleColor
    const textColor = props.theme.textColor
    const messageColor = props.theme.messageColor
    // const redeemableTextColor = props.theme.redeemableTextColor

    const model = state.campaign.models.find(m => m.make === state.lead.fields.newVehicleMake && m.name === state.lead.fields.newVehicleModel && m.year === state.lead.fields.newVehicleModelYear)

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        118
    )

    const termsMobile = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        90
    )

    if (isMobile) {
        return (
            <svg
                width="100%"
                height="833"
                ref={ref}
                viewBox="0 0 414 833"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(0 -145)">
                        <g transform="translate(10 145)">
                            <rect
                                width="391"
                                height="833"
                                x="1.5"
                                y="1.5"
                                fill="#FFF"
                                stroke={secondaryColor}
                                strokeWidth="3"
                                rx="5"
                            ></rect>
                            <path fill={secondaryColor} d="M1 255H394V319H1z"></path>
                            <text
                                fill={props.theme.voucherBg}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="11"
                                fontWeight="normal"
                                letterSpacing="-0.1"
                            >
                                <tspan x="200" y="282" textAnchor="middle">
                                    This {voucher.name} is redeemable only at
                                </tspan>
                                <tspan x="200" y="302" textAnchor="middle">
                                    <tspan fontWeight="bolder">{campaign.client.name} </tspan>
                                    Valid Until: <tspan fontWeight="bolder"><AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate}/>
                                    </tspan>
                                </tspan>
                            </text>
                            <text
                                fill={textColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="8.9"
                                fontWeight="normal"
                                textAnchor="middle"
                                transform="translate(-10 340)"
                                letterSpacing="0"
                            >
                               {termsMobile.map((line, i) => (
                                    <tspan x="50%" y={14 * (i + 1)} key={i}>{line}</tspan>
                                ))}
                            </text>
                            <text
                                fill={ternaryColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="90"
                                fontWeight="normal"
                                letterSpacing="-0.741"
                            >
                                <tspan x="200" y="140" textAnchor="middle">
                                    {currencyFormatter.format(voucher.value)}
                                </tspan>
                            </text>
                            <text
                                fill={props.theme.voucherBg}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="16"
                                fontWeight="normal"
                            >
                                <tspan x="200" y="48" textAnchor="middle">
                                    {t('voucher_mobile.prefix', {
                                        name: lead.fields.firstName
                                    })}
                                </tspan>
                            </text>
                            <text
                                fill={props.theme.voucherBg}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="16"
                                fontWeight="normal"
                            >
                                <tspan x="200" y="179" textAnchor="middle">
                                    {t('voucher_mobile.name.1', { name: voucher.name })}
                                </tspan>
                                <tspan x="200" y="207" textAnchor="middle">
                                    {t('voucher_mobile.name.2', {
                                        vehicle: `${state.lead.fields.newVehicleModelYear || ''} ${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}`
                                    })}
                                </tspan>
                            </text>
                        </g>
                        <path
                            fill={secondaryColor}
                            d="M10 573h394v401a5 5 0 01-5 5H15a5 5 0 01-5-5V573z"
                        ></path>
                        <text
                            fill={messageColor}
                            fontFamily="'Oxygen', 'sans-serif'"
                            fontSize="15"
                            fontWeight="normal"
                        >
                            <tspan x="209" y="840" textAnchor="middle">
                                {t('voucher_mobile.selection.1', {
                                    name: `${state.lead.fields.firstName}`
                                })}
                            </tspan>
                            <tspan x="209" y="865" textAnchor="middle">
                                {t('voucher_mobile.selection.2')}
                            </tspan>
                        </text>
                        <g
                            fill={titleColor}
                            fontFamily="'proxima-nova', sans-serif"
                            fontSize="16"
                            fontWeight="bolder"
                            transform="translate(0 769)"
                        >
                            <text>
                                {wrapText(`${state.lead.fields.newVehicleModelYear || ''} ${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}`).map((line, index) => (
                                    <tspan x="209" y="14" textAnchor="middle" key={index} fontSize="26px" dy={index * 30}>
                                        {line}
                                    </tspan>
                                ))}
                            </text>
                        </g>
                        {website && (
                            <g transform="translate(68 899)">
                                <rect
                                    width="280"
                                    height="46"
                                    x="0"
                                    y="0"
                                    fill={ternaryColor}
                                    rx="4"
                                    ry="4"
                                ></rect>
                                <text
                                    fill="#FFF"
                                    fontSize="18"
                                    fontWeight="bold"
                                    fontFamily="proxima-nova, sans-serif"
                                    letterSpacing="0"
                                >
                                    <tspan x="142" y="29" textAnchor="middle">
                                        <a href={website} fill={'#FFFFFF'} target="_blank" rel="noopener noreferrer">
                                            {t('voucher_mobile.selection.button')}
                                        </a>
                                    </tspan>
                                </text>
                            </g>
                        )}
                    </g>
                    <image
                        crossOrigin="anonymous"
                        x="65"
                        y="415"
                        width="304"
                        height="246"
                        xlinkHref={model.images.front_34.m}
                    />
                </g>
            </svg>
        )
    }

    return (
        <svg ref={ref} width={props.width} viewBox="0 0 559 217" version="1.1">
            <g data-name="Layer 2">
                <g data-name="Layer 1">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <rect
                                            width="559"
                                            height="213.33"
                                            x="1.5"
                                            y="1.5"
                                            fill="#fff"
                                            stroke={secondaryColor}
                                            strokeWidth="3"
                                            rx="2.35"
                                        ></rect>
                                        <path
                                            fill={secondaryColor}
                                            fillRule="evenodd"
                                            d="M351.17.79h207.68a2.36 2.36 0 012.35 2.36v210a2.36 2.36 0 01-2.35 2.36H351.17z"
                                        ></path>
                                    </g>
                                    {website && (
                                        <g>
                                            <rect
                                                fill={ternaryColor}
                                                height="26"
                                                x="380"
                                                y="160"
                                                rx="4"
                                                ry="4"
                                                width="153">
                                            </rect>
                                            <text
                                                transform="translate(430 176.3)"
                                                fill="#fff"
                                                fontSize="10.361"
                                                font-weight="bold"
                                                fontFamily="proxima-nova, sans-serif"
                                                letterSpacing="0"
                                            >
                                                <tspan>
                                                    <a href={website} fill={'#FFFFFF'} target="_blank" rel="noopener noreferrer">
                                                        {t('selection.button')}
                                                    </a>
                                                </tspan>
                                            </text>
                                        </g>
                                    )}
                                    <g
                                        fill={'#1D1D1D'}
                                        fontFamily="'Oxygen', 'sans-serif'"
                                        fontSize="9"
                                    >
                                        <text
                                            transform="translate(393.8 129.25)"
                                            letterSpacing="-0.25px"
                                        >
                                            <tspan x="60" y="0" textAnchor="middle">
                                                {t('selection.1', {
                                                    name: `${state.lead.fields.firstName}`
                                                })}
                                            </tspan>
                                            <tspan x="60" y="14" textAnchor="middle">
                                                {t('selection.2')}
                                            </tspan>
                                        </text>
                                    </g>
                                </g>
                                <g>
                                    <text
                                        fill={ternaryColor}
                                        data-name="$XXXX"
                                        letterSpacing="-0.91px"
                                    >
                                        <tspan
                                            textAnchor="middle"
                                            x="177.25" y="92.5"
                                            fontSize="70"
                                            lineHeight="134"
                                            fontFamily="proxima-nova, sans-serif"
                                            fontWeight="bold">
                                            {currencyFormatter.format(voucher.value)}
                                        </tspan>
                                    </text>
                                    <text
                                        fill={props.theme.voucherBg}
                                        fontFamily="proxima-nova, 'sans-serif'"
                                        fontSize="10"
                                        letterSpacing="0"
                                        fontWeight="400"
                                    >
                                        <tspan textAnchor="middle" x="177.25" y="30">
                                            {t('voucher.prefix', { name: lead.fields.firstName })}
                                        </tspan>
                                    </text>
                                    <g
                                        fill={props.theme.voucherBg}
                                        fontFamily="proxima-nova, 'sans-serif'"
                                        fontSize="10"
                                        letterSpacing="0"
                                        fontWeight="400"
                                    >
                                        <text
                                        >
                                            <tspan textAnchor="middle" x="177.25" y="117">
                                                {t('voucher.name', { name: voucher.name })}
                                            </tspan>
                                        </text>
                                        <text
                                        >
                                            <tspan textAnchor="middle" x="177.25" y="132">
                                                {`${state.lead.fields.newVehicleModelYear || ''} ${state.lead.fields.newVehicleModel}`}
                                            </tspan>
                                        </text>
                                    </g>
                                </g>
                                <g
                                    fill={'#A0A0A0'}
                                    fontFamily="'Oxygen', 'sans-serif'"
                                    fontSize="4.709"
                                    textAnchor='middle'
                                    transform="translate(-105 153)"
                                >
                                    <text>
                                        {terms.map((line, i) => (
                                            <tspan key={i} x="50%" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)}>{line}</tspan>
                                        ))}
                                    </text>
                                </g>
                                <g>
                                    <text
                                        fill={titleColor}
                                        data-name="2020-Escape"
                                        fontFamily="'proxima-nova', sans-serif"
                                        fontSize="14"
                                        fontWeight="bolder"
                                    >
                                        {wrapText(`${state.lead.fields.newVehicleModelYear || ''} ${state.lead.fields.newVehicleModel}`).map((line, index) => (
                                            <tspan x="455" y="95" letterSpacing="0" textAnchor="middle" dy={index * 18} key={index}>
                                                {line}
                                            </tspan>
                                        ))}
                                    </text>
                                </g>
                                <image
                                    crossOrigin="anonymous"
                                    x="345"
                                    y="-8"
                                    width="223"
                                    height="104"
                                    xlinkHref={model.images.front_34.m}
                                />
                                <path
                                    fill={secondaryColor}
                                    d="M11.74 153.73H341.17V174.57H11.74z"
                                ></path>
                                <text
                                    fill={props.theme.voucherBg}
                                    fontFamily="'proxima-nova', 'sans-serif'"
                                    fontSize="5"
                                    letterSpacing="-0.1px"
                                    fontWeight="400"
                                >
                                    <tspan x="172.5" y="166" letterSpacing="-.034em" fontSize="6" textAnchor="middle">
                                        This {voucher.name} is redeemable only at <tspan
                                            fontWeight="bolder">{campaign.client.name} </tspan>
                                        Valid Until: <tspan fontWeight="bolder">
                                            <AbsoluteTime
                                                noContainer
                                                dateFormat='long'
                                                date={campaign.expiryDate}
                                            />
                                        </tspan>
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})

Voucher.defaultProps = {
    width: 90
}

function wrapText (string: string, maxLength: number = 18) {
    return string.replace(new RegExp(`(?![^\\n]{1,${maxLength}}$)([^\\n]{1,${maxLength}})\\s`, 'g'), '$1\n').split('\n')
}

export default withTheme(supportedThemes)(Voucher)
