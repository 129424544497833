// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import ShoppingCard from 'components/ShoppingCard'
import supportedThemes from './themes/__supportedThemes'

import * as Form from 'components/Form'

import { Store } from 'Store'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

const INITIAL_VALUE = 'All vehicles'
const INITIAL_MAKE_VALUE = 'All brands'

function Step2 (props) {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)
    const [segment, setSegment] = React.useState(null)
    const [make, setMake] = React.useState(null)

    const segments = React.useMemo(() => {
        const segments = state.campaign.models.reduce((ss: Set, model) => {
            if (model.segment !== null) {
                ss.add(model.segment)
            }
            return ss
        }, new Set())
        return Array.from(segments).sort((a, b) => {
            if (a === 'Pre-Owned') {
                return 1
            }

            if (b === 'Pre-Owned') {
                return -1
            }

            return a < b ? -1 : 1
        })
    }, [state.campaign.models])

    const makes = React.useMemo(() => {
        const make = {}
        state.campaign.models.forEach(i => {
            make[i.make] = i.make
        })
        return Object.keys(make)
    }, [state.campaign.models])

    const models = React.useMemo(() => {
        return state.campaign.models.filter(m => {
            if (segment && m.segment !== segment) return false
            return !(make && m.make !== make)
        })
    }, [state.campaign.models, segment, make])

    return (
        <>
            <h2 className={styles.heading} style={{
                color: props.theme.titleColor
            }}>
                {t('heading')}
            </h2>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <div className={styles.containerWrapper}>
                            <div className={styles.gridContainer}>
                                <div className={styles.dropdown} style={{ '--color-selected': props.theme.dropdownColor, '--primary-color': props.theme.primaryColor }}>
                                    {makes.length > 1 && (
                                        <Form.Field label={'Filter vehicle brand'} placeholder={'Selet vehicle brand'}>
                                            <Form.Dropdown
                                                options={makes}
                                                trans={t}
                                                placeholder={INITIAL_MAKE_VALUE}
                                                onChange={setMake}
                                                defaultValue={make}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field label={'Filter vehicle type'} placeholder={'Filter vehicle type'}>
                                        <Form.Dropdown
                                            // error={props.errors.newVehicleMake || null}
                                            options={segments}
                                            trans={t}
                                            placeholder={INITIAL_VALUE}
                                            onChange={seg => setSegment(seg)}
                                            defaultValue={segment}
                                        />
                                    </Form.Field>
                                </div>
                                {state.campaign.models.map(m => (
                                    <ShoppingCard
                                        isVisible={models.includes(m)}
                                        voucherValue={state.campaign.incentives.voucher.type === 'variable' ? m.voucherValue : state.campaign.incentives.voucher.value}
                                        startingPrice={m.startingPrice}
                                        name={m.name}
                                        colorProp={null}
                                        year={m.year}
                                        image={m.images.front_34.m}
                                        make={m.make}
                                        segment={m.segment}
                                        key={`${m.make}.${m.name}.${m.year}`}
                                        onClick={(color) => {
                                            props.onSelect(m.make, m.name, parseInt(m.year, 10), color)
                                        }}
                                    />
                                ))}

                                {models.length === 0 && (
                                    <h1>No matches found for the current filters selected.</h1>
                                )}
                            </div>
                        </div>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

        </>
    )
}

export default withTheme(supportedThemes)(Step2)
